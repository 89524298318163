import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // Slider Range (jQuery UI)
    $( "#price_slider_range" ).slider({
      range: true,
      min: 0,
      max: 1500,
      values: [+$('#min_price').val(), +$('#max_price').val()],
      slide: (event, ui) => {
        $('#amount').val(`Rs ${ui.values[0]} - Rs ${ui.values[1]}`);
      },
      stop: (event, ui) => {
        $('#min_price').val(ui.values[0]);
        $('#max_price').val(ui.values[1]);
        Rails.fire($('#filters-container .bus-filters')[0], 'submit');
      }
    });
    $('#amount').val(`Rs ${$("#price_slider_range").slider("values", 0)} - Rs ${$("#price_slider_range").slider("values", 1)}`);

    let min_departure = parseInt($('#min_departure_time').val())
    let max_departure = parseInt($('#max_departure_time').val())

    $("#departure-time-slider").slider({
      range: true,
      min: 0,
      max: 1440,
      step: 15,
      values: [min_departure, max_departure],
      slide: function (e, ui) {
        let hours1 = Math.floor(ui.values[0] / 60);
        let minutes1 = ui.values[0] - (hours1 * 60);

        if (hours1.length == 1) hours1 = '0' + hours1;
        if (minutes1.length == 1) minutes1 = '0' + minutes1;
        if (minutes1 == 0) minutes1 = '00';
        if (hours1 >= 12) {
          if (hours1 == 12) {
            hours1 = hours1;
            minutes1 = minutes1 + " pm";
          } else {
            hours1 = hours1 - 12;
            minutes1 = minutes1 + " pm";
          }
        } else {
          hours1 = hours1;
          minutes1 = minutes1 + " am";
        }
        if (hours1 == 0) {
          hours1 = 12;
          minutes1 = minutes1;
        }
        $('.min-departure-slider').html(hours1 + ':' + minutes1);

        let hours2 = Math.floor(ui.values[1] / 60);
        let minutes2 = ui.values[1] - (hours2 * 60);

        if (hours2.length == 1) hours2 = '0' + hours2;
        if (minutes2.length == 1) minutes2 = '0' + minutes2;
        if (minutes2 == 0) minutes2 = '00';
        if (hours2 >= 12) {
          if (hours2 == 12) {
            hours2 = hours2;
            minutes2 = minutes2 + " pm";
          } else if (hours2 == 24) {
            hours2 = 11;
            minutes2 = "59 pm";
          } else {
            hours2 = hours2 - 12;
            minutes2 = minutes2 + " pm";
          }
        } else {
          hours2 = hours2;
          minutes2 = minutes2 + " am";
        }
        $('.max-departure-slider').html(hours2 + ':' + minutes2);
      },
      stop: (event, ui) => {
        $('#min_departure_time').val(ui.values[0]);
        $('#max_departure_time').val(ui.values[1]);
        Rails.fire($('#filters-container .bus-filters')[0], 'submit');
      }
    });
  }

  changeBusType() {
    Rails.fire($('#filters-container .bus-filters')[0], 'submit');
  }
}
