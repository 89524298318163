import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['promoCode']

  static values = {
    url: String,
    bookingId: String,
  }

  applyDiscount() {
    let id = this.bookingIdValue
    let url = this.urlValue
    let data = new FormData();
    data.append('id', id)
    data.append('promo_code', this.promoCodeTarget.value)

    Rails.ajax({
      type: 'post',
      url: url,
      data: data
    })
  }
}
