import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    seatDetailUrl: String,
    isAdminUser: Boolean,
    totalseats: Number,
    bustype: String,
    fare: Number,
    businessfare: Number,
    buswithhostess: String,
    busleftseats: Number,
    busrightseats: Number,
    busseats: Array
  }

  static targets = [
    'customerDetails', 'customerName', 'customerCnic',
    'customerPhone', 'customerSeatNo', 'customerAmount'
  ]

  connect() {
    this.initSeatChart()
  }

  initSeatChart() {
    this.customerDetailsVisibility(false);
    let cart = $('#selected-seats')
    let counter = $('#counter')
    let total = $('#total')
    let self = this
    let [seatsMapping, unavailableSeats] = this.busseatsValue
    let mapping = this.busSeatsMapping(seatsMapping)
    let seatNumbers = seatsMapping
    let sc = $('#seat-map').seatCharts({
      map: mapping,
      seats: {
        l: {
            price   : parseFloat(this.fareValue),
            description : 'Luxury Seat Price',
            classes : 'first-class', //your custom CSS class
            category: 'First Class'
          },
        e: {
          price   : parseFloat(this.fareValue),
          description : 'Economical Seat Price',
          classes : 'economy-class', //your custom CSS class
          category: 'Economy Class'
        },
        b: {
          price   : parseFloat(this.businessfareValue),
          description : 'Luxury Seat Price',
          classes : 'first-class', //your custom CSS class
          category: 'First Class'
        }
      },
      naming : {
        top : false,
        getLabel : function (character, row, column) {
          return parseInt(seatNumbers[row-1][column - 1]);
        },
      },
      legend : {
        node : $('#legend'),
        items : self.seatItems()
      },
      click: function () {
        if (this.status() == 'available') {
          self.customerDetailsVisibility(false);
          let liTagId = `cart-item-${this.settings.id}`
          $('<li>'+this.data().category+' Seat # '+this.settings.label+': <b>'+this.data().price+' Rs</b></li>')
            .attr('id', liTagId)
            .data('seatId', this.settings.id)
            .appendTo(cart);

          counter.text(sc.find('selected').length+1);
          total.text(self.recalculateTotal(sc)+this.data().price);

          $(`<input type="hidden" name="booking[booked_seats][]" value="${this.settings.label}" id="seat-${this.settings.id}">`).appendTo($(`#${liTagId}`))
          $(`<input type="hidden" name="booking[seats_gender][]" value="${this.settings.label}M" id="seat-${this.settings.id}M">`).appendTo($(`#${liTagId}`))

          return 'selected';
        }
        else if (this.status() == 'selected') {
          self.customerDetailsVisibility(false);
          $(`#seat-${this.settings.id}M`).val(`${this.settings.label}F`)
          return 'doubleselected';
        }
        else if (this.status() == 'doubleselected') {
          self.customerDetailsVisibility(false);
          counter.text((sc.find('selected').length + sc.find('doubleselected').length)-1);
          let totalAmount = self.recalculateTotal(sc) - this.data().price
          total.text(totalAmount > 0 ? totalAmount : 0);
          $('#cart-item-'+this.settings.id).remove();

          return 'available';
        }
        else if (this.status() == 'unavailable') {
          self.getCustomerDetails(this.settings.label);
          return 'unavailable';
        }
        else
          return this.style();
      }
    });

    // sc.get(['1_2', '4_1', '7_1', '7_2']).status('unavailable');
    sc.get(unavailableSeats).status('unavailable');
  }

  recalculateTotal(sc) {
    let total = 0;
    sc.find('selected').each(function () {
      total += this.data().price;
    });

    sc.find('doubleselected').each(function () {
      total += this.data().price;
    });

    return total;
  }

  busSeatsMapping(seatsMapping) {
    let mapping = new Array()
    let character = this.getBusTypeCharacter()
    let row_seats = this.busleftseatsValue + this.busrightseatsValue
    console.log("Row: ", parseInt(this.totalseatsValue / row_seats))
    for(let i = 0; i < seatsMapping.length; i++) {
      let row = ""
      for(let j = 0; j < 5; j++) {
        if(parseInt(seatsMapping[i][j]) == 0)
          row += "_"
        else if(this.bustypeValue == 'business' && parseInt(seatsMapping[i][j]) <= 9)
          row += character[0]
        else if(this.bustypeValue == 'business' && parseInt(seatsMapping[i][j]) > 9)
          row += character[1]
        else
          row += character[0] //for economical and luxury bus types
      }

      mapping.push(row)
    }

    return mapping;
  }

  getBusTypeCharacter() {
    if(this.bustypeValue == 'economical')
      return ['e'];
    else if(this.bustypeValue == 'luxury')
      return ['l'];
    else
      return ['b', 'e'];
  }

  seatItems() {
    let items = new Array()
    if(this.bustypeValue == 'economical')
      items.push(['e', 'available', 'Economy Class'])
    else if(this.bustypeValue == 'luxury')
      items.push(['l', 'available',   'Luxury Class'])
    else {
      items = items.concat([['e', 'available', 'Economy Class'], ['l', 'available', 'Luxury Class']])
    }

    items = items.concat([[ 'f', 'unavailable', 'Booked'], [ '', 'selected', 'Male' ], [ '', 'doubleselected', 'Female' ]])
    return items;
  }

  getCustomerDetails(seat_no) {
    if (this.isAdminUserValue) {
      let url = this.seatDetailUrlValue + '&seat_no=' + seat_no;

      fetch(url)
        .then(response => response.json())
        .then(response => {
          if (response.booking) {
            this.customerDetailsVisibility(true);
            this.customerNameTarget.innerHTML = response.booking.traveler_name;
            this.customerCnicTarget.innerHTML = response.booking.traveler_cnic;
            this.customerPhoneTarget.innerHTML = response.booking.traveler_phone;
            this.customerSeatNoTarget.innerHTML = response.booking.seats_gender;
            this.customerAmountTarget.innerHTML = response.booking.price;
          } else {
            this.customerDetailsVisibility(false);
            this.customerNameTarget.innerHTML = '';
            this.customerCnicTarget.innerHTML = '';
            this.customerPhoneTarget.innerHTML = '';
            this.customerSeatNoTarget.innerHTML = '';
            this.customerAmountTarget.innerHTML = '';
          }
        })
        .catch(err => console.log(err));
    } else {
      // console.log("You are unauthorize to get customer details. Thank you!");
    }
  }

  customerDetailsVisibility(visible=false) {
    if (!this.hasCustomerAmountTarget) return;

    if(visible) {
      this.customerDetailsTarget.classList.remove('hide');
    } else {
      this.customerDetailsTarget.classList.add('hide');
    }
  }
}
