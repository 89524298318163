import { Controller } from "stimulus"
import 'clockpicker/dist/bootstrap-clockpicker.min';

export default class extends Controller {
  connect() {
    $(".clockpicker").clockpicker({ default: 'now' });

    $(document).on('turbolinks:before-cache', () => {
      $('.clockpicker').clockpicker('destroy').addClass('clockpicker');
    })
  }
}
