import { Controller } from "stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  connect() {
    flatpickr("[data-behavior='flatpickr']",{
      altInput: true,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      minDate: "today"
    })

    $('.datepicker').flatpickr({
      altInput: true,
      dateFormat: "Y-m-d",
      minDate: "today",
    })

    $('.datepicker-with-prev-dates').flatpickr({
      altInput: true,
      dateFormat: "Y-m-d",
    })

    $('.datetimepicker').flatpickr({
      altInput: true,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
    })

    $('.timepicker').flatpickr({
      enableTime: true,
      noCalendar: true,
      dateFormat: "G:i K",
    })

    $(document).on('turbolinks:before-cache', () => {
      $('.datepicker, .datepicker-with-prev-dates, .datetimepicker').flatpickr('destroy').addClass('datepicker');
    })
  }
}
