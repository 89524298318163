import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['useWalletBalance', 'walletBalanceDetails', 'totalPayableDetails']

  static values = {
    walletBalance: Number,
    totalPayable: Number,
  }

  toggleWalletBalance() {
    if (this.useWalletBalanceTarget.checked) {
      this.walletBalanceDetailsTarget.style.display = 'block'
      if (this.walletBalanceValue >= this.totalPayableValue)
        this.totalPayableDetailsTarget.innerHTML = 'Rs 0'
      else
        this.totalPayableDetailsTarget.innerHTML = `Rs ${this.totalPayableValue - this.walletBalanceValue}`
    } else {
      this.walletBalanceDetailsTarget.style.display = 'none'
      this.totalPayableDetailsTarget.innerHTML = `Rs ${this.totalPayableValue}`
    }
  }
}
