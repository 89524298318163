// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.jQuery = window.$ = require('jquery')
require('javascripts/customer/jquery-ui/jquery-ui.min')
require('javascripts/customer/bootstrap/js/bootstrap.bundle.min')
require('javascripts/customer/owl.carousel/owl.carousel.min')
require('javascripts/customer/daterangepicker/moment.min')
require('javascripts/customer/daterangepicker/daterangepicker')
require('javascripts/customer/jquery-seat-charts/jquery.seat-charts')
require('javascripts/customer/theme')
require('customer_controllers')
require('plugins')
require('shared_controllers')
