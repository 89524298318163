import { Controller } from "stimulus"
import 'bootstrap-select/dist/js/bootstrap-select.min';

export default class extends Controller {
  connect() {
    $(".selectpicker").selectpicker();

    $(document).on('turbolinks:before-cache', () => {
      $('.selectpicker').selectpicker('destroy').addClass('selectpicker');
    })
  }
}
