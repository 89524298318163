import { Controller } from "stimulus"

export default class extends Controller {
  originCitiesSelect = document.getElementById('origin_city_id')
  desintationCitiesSelect = document.getElementById('destination_city_id')

  initialize() {
    if (document.getElementById('origin_city_id')) {
      document.getElementById('origin_city_id').options[0].remove();
      document.getElementById('destination_city_id').options[0].remove();
    }
  }

  onServiceSelect() {
    let serviceCities = $('#service_id :selected').data('cities')

    for (let index = 1; index < this.originCitiesSelect.length; index++) {
      let optionValue = parseInt(this.originCitiesSelect.options[index].value);

      if (serviceCities.includes(optionValue)) {
        $(this.originCitiesSelect.options[index]).show();
        $(this.desintationCitiesSelect.options[index]).show();
      } else {
        $(this.originCitiesSelect.options[index]).hide();
        $(this.desintationCitiesSelect.options[index]).hide();
      }
    }
  }

  showLoader() {
    if ($('#booking-form')[0].checkValidity())
      $('#preloader').show(); 
  }
}
