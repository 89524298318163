import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(".yearpicker").yearpicker({ year: new Date().getFullYear() });

    $(document).on('turbolinks:before-cache', () => {
      $('.yearpicker').clockpicker('destroy').addClass('yearpicker');
    })
  }
}
